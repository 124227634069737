import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import pwpImage from './pwp.jpg';

function App() {
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [prices, setPrices] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const bitcoinResponse = await axios.get('https://api.coindesk.com/v1/bpi/currentprice/BTC.json');
      setBitcoinPrice(bitcoinResponse.data.bpi.USD.rate_float);

      const pricesResponse = await axios.get('./price.json');
      setPrices(pricesResponse.data);
    }

    fetchData();
  }, []);

  if (!prices) {
    return <div>Loading...</div>;
  }

  const progress1 = (((prices.burnedBrc20 + (prices.brcsolmigrated * 100)) / 777000000) * 100).toFixed(2);
  const progress2 = (((prices.burnedOrdinals - prices.burnedBrc20) / 223000000) * 100).toFixed(2);
  const progress3 = (((prices.burnedBrc20 + (prices.brcsolmigrated * 100) + 191445660) / 968445660) * 100).toFixed(2);
  const progress4 = ((prices.bridged / 7770000) * 100).toFixed(2);
  const progress5 = ((prices.brcsolmigrated / prices.bridged) * 100).toFixed(2);
  const potentialBurn = Math.round(223000000 - prices.totalBurned);
  const marketcap = (prices.magicEden / 100000000) * bitcoinPrice * 1000000000;
  const marketcap2 = (prices.magicEden2 / 100000000) * bitcoinPrice * 100000000000;
  const progress6 = ((marketcap / marketcap2) * 100).toFixed(2);
  const progress7 = ((marketcap / 13000000000) * 100).toFixed(2);
  
  // Convert marketcap and marketcap2 to locale-specific strings for display purposes
  const marketcapDisplay = Number(marketcap.toFixed(2)).toLocaleString();

  const difference = (marketcap2 - marketcap) / 1e6;

  function timeAgo(timestamp) {
    const millisecondsAgo = Date.now() - timestamp;
    const secondsAgo = Math.floor(millisecondsAgo / 1000);
    if (secondsAgo < 60) {
      return `Updated ${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago`;
    }
    const minutesAgo = Math.floor(secondsAgo / 60);
    if (minutesAgo < 60) {
      return `Updated ${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
    }
    const hoursAgo = Math.floor(minutesAgo / 60);
    return `Updated ${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>PUPS•WORLD•PEACE Data</h1>
        <p className="marketcap">Marketcap: ${marketcapDisplay}</p>
        <span className="title">{timeAgo(prices.timestamp)}</span>
        <div className="section-container">
        <div className="section">
  <h2>Runes</h2>
  <div className="row">
    <div className="column">Solana:</div>
    <div className="column">${(prices.solrune).toFixed(5)}</div>
    <div className="column">
      <a href="https://dexscreener.com/solana/fnbnsbyfth91vdsdzqz9apn7ya3pmtpc8rhpqtzsujvp" target="_blank" rel="noopener noreferrer" className="button">View</a>
    </div>
  </div>
  <div className="row">
    <div className="column">Unisat:</div>
    <div className="column">${((prices.unisat / 100000000) * bitcoinPrice).toFixed(5)}</div>
    <div className="column">
      <a href="https://unisat.io/runes/market?tick=PUPS%E2%80%A2WORLD%E2%80%A2PEACE" target="_blank" rel="noopener noreferrer" className="button">View</a>
    </div>
  </div>
  <div className="row">
    <div className="column">Magic Eden:</div>
    <div className="column">${((prices.magicEden / 100000000) * bitcoinPrice).toFixed(5)}</div>
    <div className="column">
      <a href="https://magiceden.io/runes/PUPS%E2%80%A2WORLD%E2%80%A2PEACE" target="_blank" rel="noopener noreferrer" className="button">View</a>
    </div>
  </div>
  {
  /* 
  <div className="row">
    <div className="column">OKX:</div>
    <div className="column">${((prices.okx / 100000000) * bitcoinPrice).toFixed(5)}</div>
    <div className="column">
      <a href="https://www.okx.com/web3/marketplace/runes/token/PUPS%E2%80%A2WORLD%E2%80%A2PEACE/840000:41" target="_blank" rel="noopener noreferrer" className="button">View</a>
    </div>
  </div>
<div className="row">
  <div className="column">Saturn:</div>
  <div className="column">
    {prices.saturn === 0 ? "n/a" : `$${(((prices.saturn * 10**18) / 100000000) * bitcoinPrice).toFixed(5)}`}
  </div>
  <div className="column">
    <a href="https://www.saturnbtc.io/app/limit/pupsworldpeace-sat" target="_blank" rel="noopener noreferrer" className="button">View</a>
  </div>
</div>
*/
}
</div>
        </div>
        <div className="full-width-section progress-section">
          <h3>#1 Rune by Marketcap Goal</h3>
          <ProgressBar 
            className="custom-progress-bar" 
            now={progress6} 
            label={difference > 0 ? `${progress6}% ($${difference.toFixed(1)}M to go)` : "Goal Achieved"} 
            animated 
          />
          <h3>$13B Prophecy</h3>
          <ProgressBar className="custom-progress-bar" now={progress7} label={`${progress7}%`} animated />
          <h3>PUPS Bridged to SOL</h3>
          <ProgressBar className="custom-progress-bar2" now={progress4} label={`${progress4}%`} />
        </div>
        <h3>Burned 🔥: 31,554,340 PUPS•WORLD•PEACE</h3>
        <p>Built by <a href="https://x.com/OrdWatchers" target="_blank" rel="noopener noreferrer">OrdWatchers</a></p>
      </header>
      <img src={pwpImage} alt="PWP" className="pwp-image" />
    </div>
  );
}

export default App;